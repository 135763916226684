<template>
    <div>
       
    </div>
</template>
<script>
export default {
    inject:["Bus"],
    data() {
        return {
            pageTitle:"Result",
            loading:false
        }
    },
    mounted(){
        this.Bus.$emit("title-change",this.pageTitle);
    }
}
</script>